.floating-whatsapp-button {
  position: fixed;
  bottom: 30px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25d366;
  color: #fff;
  border-radius: 30px; /* Increase this value to make the oval larger */
  padding: 12px 16px; /* Add some padding to create space for content */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: transform 0.3s;
}

.floating-whatsapp-button:hover {
  transform: scale(1.1);
}

.floating-whatsapp-button img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.floating-whatsapp-button span {
  font-size: 16px; /* Increase the font size */
  font-weight: bold;
}
