.container3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}
/* font-family: sans-serif; */
  
.container3 img{
    flex: 1;
    width: 500px;

}

.righttext {
    position:relative;
    top : -300px;
    margin-top: 30px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: start;
    text-align: center;
}
.righttext p{
    font-size: 3rem;
}